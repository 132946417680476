import { CommonModule } from "@angular/common";
import {
  AfterViewInit,
  Component,
  ElementRef,
  inject,
  ViewChild,
} from "@angular/core";
import { datadogRum } from "@datadog/browser-rum";
import { ShieldModule } from "@shield/angular";
import { CookieBannerService } from "../service/cookie-banner.service";

@Component({
  selector: "app-cookie-banner",
  standalone: true,
  imports: [CommonModule, ShieldModule],
  templateUrl: "./cookie-banner.component.html",
  styleUrl: "./cookie-banner.component.scss",
  host: { "[id]": '"cookie-banner"' },
})
export class CookieBannerComponent implements AfterViewInit {
  @ViewChild("dataPrivacyLink") dataPrivacyLink?: ElementRef;
  public showCookieDetails: boolean = false;
  public detailBlock1Expanded: boolean = false;
  public detailBlock1Title: string = "Details anzeigen";
  public detailBlock2Expanded: boolean = false;
  public detailBlock2Title: string = "Details anzeigen";

  private readonly cookieBannerService = inject(CookieBannerService);

  public ngAfterViewInit(): void {
    if (this.dataPrivacyLink) {
      setTimeout(
        () => (this.dataPrivacyLink?.nativeElement as HTMLElement).blur(),
        75
      );
    }
  }

  public closeCookieBanner(fullConsent: boolean) {
    localStorage.setItem("privacy_consent", String(fullConsent));
    if (fullConsent) {
      datadogRum.setTrackingConsent("granted");
    } else {
      datadogRum.setTrackingConsent("not-granted");
    }
    this.cookieBannerService.closeCookieBanner();
  }

  public toggleCookieDetails() {
    this.showCookieDetails = !this.showCookieDetails;
  }

  public toggleDetailBlock1(newStatus: boolean): void {
    this.detailBlock1Expanded = newStatus;
    if (newStatus) {
      this.detailBlock1Title = "Details verbergen";
    } else {
      this.detailBlock1Title = "Details anzeigen";
    }
  }

  public toggleDetailBlock2(newStatus: boolean): void {
    this.detailBlock2Expanded = newStatus;
    if (newStatus) {
      this.detailBlock2Title = "Details verbergen";
    } else {
      this.detailBlock2Title = "Details anzeigen";
    }
  }
}
