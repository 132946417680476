import * as i0 from '@angular/core';
import { Directive, HostListener, Component, ChangeDetectionStrategy, NgModule } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { __decorate } from 'tslib';
import { fromEvent } from 'rxjs';
import { CommonModule } from '@angular/common';
const _c0 = ["*"];
class ValueAccessor {
  el;
  onChange = () => {};
  onTouched = () => {};
  lastValue;
  constructor(el) {
    this.el = el;
  }
  writeValue(value) {
    this.el.nativeElement.value = this.lastValue = value == null ? '' : value;
  }
  handleChangeEvent(value) {
    if (value !== this.lastValue) {
      this.lastValue = value;
      this.onChange(value);
    }
  }
  _handleBlurEvent() {
    this.onTouched();
  }
  registerOnChange(fn) {
    this.onChange = fn;
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled) {
    this.el.nativeElement.disabled = isDisabled;
  }
  /** @nocollapse */
  static ɵfac = function ValueAccessor_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || ValueAccessor)(i0.ɵɵdirectiveInject(i0.ElementRef));
  };
  /** @nocollapse */
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: ValueAccessor,
    hostBindings: function ValueAccessor_HostBindings(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵlistener("focusout", function ValueAccessor_focusout_HostBindingHandler() {
          return ctx._handleBlurEvent();
        });
      }
    }
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ValueAccessor, [{
    type: Directive,
    args: [{}]
  }], () => [{
    type: i0.ElementRef
  }], {
    _handleBlurEvent: [{
      type: HostListener,
      args: ['focusout']
    }]
  });
})();
class BooleanValueAccessor extends ValueAccessor {
  constructor(el) {
    super(el);
  }
  writeValue(value) {
    this.el.nativeElement.checked = this.lastValue = value == null ? false : value;
  }
  /** @nocollapse */
  static ɵfac = function BooleanValueAccessor_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || BooleanValueAccessor)(i0.ɵɵdirectiveInject(i0.ElementRef));
  };
  /** @nocollapse */
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: BooleanValueAccessor,
    selectors: [["s-checkbox"], ["s-switch"], ["s-radio"]],
    hostBindings: function BooleanValueAccessor_HostBindings(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵlistener("sChange", function BooleanValueAccessor_sChange_HostBindingHandler($event) {
          return ctx.handleChangeEvent($event.target.checked);
        });
      }
    },
    features: [i0.ɵɵProvidersFeature([{
      provide: NG_VALUE_ACCESSOR,
      useExisting: BooleanValueAccessor,
      multi: true
    }]), i0.ɵɵInheritDefinitionFeature]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BooleanValueAccessor, [{
    type: Directive,
    args: [{
      /* tslint:disable-next-line:directive-selector */
      selector: 's-checkbox, s-switch, s-radio',
      host: {
        '(sChange)': 'handleChangeEvent($event.target.checked)'
      },
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: BooleanValueAccessor,
        multi: true
      }]
    }]
  }], () => [{
    type: i0.ElementRef
  }], null);
})();
class NumericValueAccessor extends ValueAccessor {
  constructor(el) {
    super(el);
  }
  registerOnChange(fn) {
    super.registerOnChange(value => {
      fn(value === '' ? null : parseFloat(value));
    });
  }
  /** @nocollapse */
  static ɵfac = function NumericValueAccessor_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || NumericValueAccessor)(i0.ɵɵdirectiveInject(i0.ElementRef));
  };
  /** @nocollapse */
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: NumericValueAccessor,
    selectors: [["s-slider"]],
    hostBindings: function NumericValueAccessor_HostBindings(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵlistener("sChange", function NumericValueAccessor_sChange_HostBindingHandler($event) {
          return ctx.handleChangeEvent($event.target.value);
        });
      }
    },
    features: [i0.ɵɵProvidersFeature([{
      provide: NG_VALUE_ACCESSOR,
      useExisting: NumericValueAccessor,
      multi: true
    }]), i0.ɵɵInheritDefinitionFeature]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NumericValueAccessor, [{
    type: Directive,
    args: [{
      /* tslint:disable-next-line:directive-selector */
      selector: 's-slider',
      host: {
        '(sChange)': 'handleChangeEvent($event.target.value)'
      },
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: NumericValueAccessor,
        multi: true
      }]
    }]
  }], () => [{
    type: i0.ElementRef
  }], null);
})();
class TextValueAccessor extends ValueAccessor {
  constructor(el) {
    super(el);
  }
  /** @nocollapse */
  static ɵfac = function TextValueAccessor_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || TextValueAccessor)(i0.ɵɵdirectiveInject(i0.ElementRef));
  };
  /** @nocollapse */
  static ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
    type: TextValueAccessor,
    selectors: [["s-text-field"], ["s-textarea"], ["s-select"], ["s-choice-list"], ["s-choice-group"], ["s-comparison-table"]],
    hostBindings: function TextValueAccessor_HostBindings(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵlistener("sInput", function TextValueAccessor_sInput_HostBindingHandler($event) {
          return ctx.handleChangeEvent($event.target.value);
        })("sChange", function TextValueAccessor_sChange_HostBindingHandler($event) {
          return ctx.handleChangeEvent($event.target.value);
        })("sChangeComparisonTable", function TextValueAccessor_sChangeComparisonTable_HostBindingHandler($event) {
          return ctx.handleChangeEvent($event.target.value);
        });
      }
    },
    features: [i0.ɵɵProvidersFeature([{
      provide: NG_VALUE_ACCESSOR,
      useExisting: TextValueAccessor,
      multi: true
    }]), i0.ɵɵInheritDefinitionFeature]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TextValueAccessor, [{
    type: Directive,
    args: [{
      /* tslint:disable-next-line:directive-selector */
      selector: 's-text-field, s-textarea, s-select, s-choice-list, s-choice-group, s-comparison-table',
      host: {
        '(sInput)': 'handleChangeEvent($event.target.value)',
        '(sChange)': 'handleChangeEvent($event.target.value)',
        '(sChangeComparisonTable)': 'handleChangeEvent($event.target.value)'
      },
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: TextValueAccessor,
        multi: true
      }]
    }]
  }], () => [{
    type: i0.ElementRef
  }], null);
})();

/* eslint-disable */
/* tslint:disable */
const proxyInputs = (Cmp, inputs) => {
  const Prototype = Cmp.prototype;
  inputs.forEach(item => {
    Object.defineProperty(Prototype, item, {
      get() {
        return this.el[item];
      },
      set(val) {
        this.z.runOutsideAngular(() => this.el[item] = val);
      },
      /**
       * In the event that proxyInputs is called
       * multiple times re-defining these inputs
       * will cause an error to be thrown. As a result
       * we set configurable: true to indicate these
       * properties can be changed.
       */
      configurable: true
    });
  });
};
const proxyMethods = (Cmp, methods) => {
  const Prototype = Cmp.prototype;
  methods.forEach(methodName => {
    Prototype[methodName] = function () {
      const args = arguments;
      return this.z.runOutsideAngular(() => this.el[methodName].apply(this.el, args));
    };
  });
};
const proxyOutputs = (instance, el, events) => {
  events.forEach(eventName => instance[eventName] = fromEvent(el, eventName));
};
const defineCustomElement = (tagName, customElement) => {
  if (customElement !== undefined && typeof customElements !== 'undefined' && !customElements.get(tagName)) {
    customElements.define(tagName, customElement);
  }
};
// tslint:disable-next-line: only-arrow-functions
function ProxyCmp(opts) {
  const decorator = function (cls) {
    const {
      defineCustomElementFn,
      inputs,
      methods
    } = opts;
    if (defineCustomElementFn !== undefined) {
      defineCustomElementFn();
    }
    if (inputs) {
      proxyInputs(cls, inputs);
    }
    if (methods) {
      proxyMethods(cls, methods);
    }
    return cls;
  };
  return decorator;
}
let SBackdrop = class SBackdrop {
  z;
  el;
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['sShow', 'sHide', 'sClick']);
  }
  /** @nocollapse */
  static ɵfac = function SBackdrop_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || SBackdrop)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
  };
  /** @nocollapse */
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: SBackdrop,
    selectors: [["s-backdrop"]],
    inputs: {
      absolute: "absolute",
      show: "show"
    },
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function SBackdrop_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2,
    changeDetection: 0
  });
};
SBackdrop = __decorate([ProxyCmp({
  inputs: ['absolute', 'show']
})], SBackdrop);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SBackdrop, [{
    type: Component,
    args: [{
      selector: 's-backdrop',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['absolute', 'show']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let SBadge = class SBadge {
  z;
  el;
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  /** @nocollapse */
  static ɵfac = function SBadge_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || SBadge)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
  };
  /** @nocollapse */
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: SBadge,
    selectors: [["s-badge"]],
    inputs: {
      color: "color",
      content: "content",
      dot: "dot",
      icon: "icon",
      iconVariant: "iconVariant",
      inline: "inline",
      size: "size"
    },
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function SBadge_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2,
    changeDetection: 0
  });
};
SBadge = __decorate([ProxyCmp({
  inputs: ['color', 'content', 'dot', 'icon', 'iconVariant', 'inline', 'size']
})], SBadge);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SBadge, [{
    type: Component,
    args: [{
      selector: 's-badge',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'content', 'dot', 'icon', 'iconVariant', 'inline', 'size']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let SBanner = class SBanner {
  z;
  el;
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['sRemove']);
  }
  /** @nocollapse */
  static ɵfac = function SBanner_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || SBanner)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
  };
  /** @nocollapse */
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: SBanner,
    selectors: [["s-banner"]],
    inputs: {
      color: "color",
      density: "density",
      dismissible: "dismissible",
      icon: "icon",
      leadingText: "leadingText",
      severity: "severity",
      supportingText: "supportingText",
      variant: "variant"
    },
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function SBanner_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2,
    changeDetection: 0
  });
};
SBanner = __decorate([ProxyCmp({
  inputs: ['color', 'density', 'dismissible', 'icon', 'leadingText', 'severity', 'supportingText', 'variant']
})], SBanner);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SBanner, [{
    type: Component,
    args: [{
      selector: 's-banner',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'density', 'dismissible', 'icon', 'leadingText', 'severity', 'supportingText', 'variant']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let SBottomSheet = class SBottomSheet {
  z;
  el;
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['sOpenBottomSheet', 'sCloseBottomSheet']);
  }
  /** @nocollapse */
  static ɵfac = function SBottomSheet_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || SBottomSheet)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
  };
  /** @nocollapse */
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: SBottomSheet,
    selectors: [["s-bottom-sheet"]],
    inputs: {
      backdrop: "backdrop",
      closeButton: "closeButton",
      dismissible: "dismissible",
      form: "form",
      headline: "headline",
      inset: "inset",
      persistent: "persistent",
      shape: "shape",
      show: "show"
    },
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function SBottomSheet_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2,
    changeDetection: 0
  });
};
SBottomSheet = __decorate([ProxyCmp({
  inputs: ['backdrop', 'closeButton', 'dismissible', 'form', 'headline', 'inset', 'persistent', 'shape', 'show'],
  methods: ['open', 'close']
})], SBottomSheet);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SBottomSheet, [{
    type: Component,
    args: [{
      selector: 's-bottom-sheet',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['backdrop', 'closeButton', 'dismissible', 'form', 'headline', 'inset', 'persistent', 'shape', 'show']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let SBreadcrumb = class SBreadcrumb {
  z;
  el;
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  /** @nocollapse */
  static ɵfac = function SBreadcrumb_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || SBreadcrumb)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
  };
  /** @nocollapse */
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: SBreadcrumb,
    selectors: [["s-breadcrumb"]],
    inputs: {
      maxItems: "maxItems"
    },
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function SBreadcrumb_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2,
    changeDetection: 0
  });
};
SBreadcrumb = __decorate([ProxyCmp({
  inputs: ['maxItems']
})], SBreadcrumb);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SBreadcrumb, [{
    type: Component,
    args: [{
      selector: 's-breadcrumb',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['maxItems']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let SButton = class SButton {
  z;
  el;
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['sClick', 'sBlur', 'sFocus']);
  }
  /** @nocollapse */
  static ɵfac = function SButton_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || SButton)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
  };
  /** @nocollapse */
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: SButton,
    selectors: [["s-button"]],
    inputs: {
      autofocus: "autofocus",
      color: "color",
      disabled: "disabled",
      download: "download",
      external: "external",
      fullWidth: "fullWidth",
      label: "label",
      loading: "loading",
      sTabindex: "sTabindex",
      size: "size",
      type: "type",
      url: "url",
      value: "value",
      variant: "variant"
    },
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function SButton_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2,
    changeDetection: 0
  });
};
SButton = __decorate([ProxyCmp({
  inputs: ['autofocus', 'color', 'disabled', 'download', 'external', 'fullWidth', 'label', 'loading', 'sTabindex', 'size', 'type', 'url', 'value', 'variant'],
  methods: ['setFocus', 'setBlur', 'setClick']
})], SButton);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SButton, [{
    type: Component,
    args: [{
      selector: 's-button',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['autofocus', 'color', 'disabled', 'download', 'external', 'fullWidth', 'label', 'loading', 'sTabindex', 'size', 'type', 'url', 'value', 'variant']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let SCard = class SCard {
  z;
  el;
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  /** @nocollapse */
  static ɵfac = function SCard_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || SCard)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
  };
  /** @nocollapse */
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: SCard,
    selectors: [["s-card"]],
    inputs: {
      elevation: "elevation",
      headline: "headline",
      subline: "subline",
      variant: "variant"
    },
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function SCard_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2,
    changeDetection: 0
  });
};
SCard = __decorate([ProxyCmp({
  inputs: ['elevation', 'headline', 'subline', 'variant']
})], SCard);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SCard, [{
    type: Component,
    args: [{
      selector: 's-card',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['elevation', 'headline', 'subline', 'variant']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let SCheckbox = class SCheckbox {
  z;
  el;
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['sChange', 'sCheckboxChange', 'sBlur', 'sFocus']);
  }
  /** @nocollapse */
  static ɵfac = function SCheckbox_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || SCheckbox)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
  };
  /** @nocollapse */
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: SCheckbox,
    selectors: [["s-checkbox"]],
    inputs: {
      checked: "checked",
      disabled: "disabled",
      name: "name",
      readonly: "readonly",
      required: "required",
      severity: "severity",
      value: "value"
    },
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function SCheckbox_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2,
    changeDetection: 0
  });
};
SCheckbox = __decorate([ProxyCmp({
  inputs: ['checked', 'disabled', 'name', 'readonly', 'required', 'severity', 'value'],
  methods: ['setFocus', 'setBlur']
})], SCheckbox);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SCheckbox, [{
    type: Component,
    args: [{
      selector: 's-checkbox',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['checked', 'disabled', 'name', 'readonly', 'required', 'severity', 'value']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let SChip = class SChip {
  z;
  el;
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['sDelete', 'sClick', 'sBlur', 'sFocus']);
  }
  /** @nocollapse */
  static ɵfac = function SChip_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || SChip)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
  };
  /** @nocollapse */
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: SChip,
    selectors: [["s-chip"]],
    inputs: {
      clickable: "clickable",
      color: "color",
      deletable: "deletable",
      disabled: "disabled",
      shape: "shape",
      type: "type",
      variant: "variant"
    },
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function SChip_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2,
    changeDetection: 0
  });
};
SChip = __decorate([ProxyCmp({
  inputs: ['clickable', 'color', 'deletable', 'disabled', 'shape', 'type', 'variant'],
  methods: ['setFocus', 'setBlur', 'setClick', 'setDelete']
})], SChip);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SChip, [{
    type: Component,
    args: [{
      selector: 's-chip',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['clickable', 'color', 'deletable', 'disabled', 'shape', 'type', 'variant']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let SChoiceGroup = class SChoiceGroup {
  z;
  el;
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['sChange']);
  }
  /** @nocollapse */
  static ɵfac = function SChoiceGroup_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || SChoiceGroup)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
  };
  /** @nocollapse */
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: SChoiceGroup,
    selectors: [["s-choice-group"]],
    inputs: {
      density: "density",
      direction: "direction",
      disabled: "disabled",
      readonly: "readonly",
      severity: "severity",
      value: "value"
    },
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function SChoiceGroup_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2,
    changeDetection: 0
  });
};
SChoiceGroup = __decorate([ProxyCmp({
  inputs: ['density', 'direction', 'disabled', 'readonly', 'severity', 'value']
})], SChoiceGroup);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SChoiceGroup, [{
    type: Component,
    args: [{
      selector: 's-choice-group',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['density', 'direction', 'disabled', 'readonly', 'severity', 'value']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let SChoiceList = class SChoiceList {
  z;
  el;
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['sChange']);
  }
  /** @nocollapse */
  static ɵfac = function SChoiceList_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || SChoiceList)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
  };
  /** @nocollapse */
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: SChoiceList,
    selectors: [["s-choice-list"]],
    inputs: {
      density: "density",
      direction: "direction",
      disabled: "disabled",
      inputLeft: "inputLeft",
      label: "label",
      multiple: "multiple",
      nowrap: "nowrap",
      readonly: "readonly",
      severity: "severity",
      spacing: "spacing",
      value: "value"
    },
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function SChoiceList_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2,
    changeDetection: 0
  });
};
SChoiceList = __decorate([ProxyCmp({
  inputs: ['density', 'direction', 'disabled', 'inputLeft', 'label', 'multiple', 'nowrap', 'readonly', 'severity', 'spacing', 'value']
})], SChoiceList);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SChoiceList, [{
    type: Component,
    args: [{
      selector: 's-choice-list',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['density', 'direction', 'disabled', 'inputLeft', 'label', 'multiple', 'nowrap', 'readonly', 'severity', 'spacing', 'value']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let SChoiceListItem = class SChoiceListItem {
  z;
  el;
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['sClick', 'sChange']);
  }
  /** @nocollapse */
  static ɵfac = function SChoiceListItem_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || SChoiceListItem)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
  };
  /** @nocollapse */
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: SChoiceListItem,
    selectors: [["s-choice-list-item"]],
    inputs: {
      choiceType: "choiceType",
      density: "density",
      disabled: "disabled",
      inputLeft: "inputLeft",
      leadingText: "leadingText",
      readonly: "readonly",
      selected: "selected",
      severity: "severity",
      supportingText: "supportingText",
      value: "value"
    },
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function SChoiceListItem_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2,
    changeDetection: 0
  });
};
SChoiceListItem = __decorate([ProxyCmp({
  inputs: ['choiceType', 'density', 'disabled', 'inputLeft', 'leadingText', 'readonly', 'selected', 'severity', 'supportingText', 'value']
})], SChoiceListItem);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SChoiceListItem, [{
    type: Component,
    args: [{
      selector: 's-choice-list-item',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['choiceType', 'density', 'disabled', 'inputLeft', 'leadingText', 'readonly', 'selected', 'severity', 'supportingText', 'value']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let SComparisonTable = class SComparisonTable {
  z;
  el;
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['sChangeComparisonTable']);
  }
  /** @nocollapse */
  static ɵfac = function SComparisonTable_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || SComparisonTable)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
  };
  /** @nocollapse */
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: SComparisonTable,
    selectors: [["s-comparison-table"]],
    inputs: {
      choices: "choices",
      value: "value"
    },
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function SComparisonTable_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2,
    changeDetection: 0
  });
};
SComparisonTable = __decorate([ProxyCmp({
  inputs: ['choices', 'value']
})], SComparisonTable);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SComparisonTable, [{
    type: Component,
    args: [{
      selector: 's-comparison-table',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['choices', 'value']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let SComparisonTableChoiceItem = class SComparisonTableChoiceItem {
  z;
  el;
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['sBlur', 'sFocus', 'sChangeComparisonTableChoiceItem']);
  }
  /** @nocollapse */
  static ɵfac = function SComparisonTableChoiceItem_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || SComparisonTableChoiceItem)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
  };
  /** @nocollapse */
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: SComparisonTableChoiceItem,
    selectors: [["s-comparison-table-choice-item"]],
    inputs: {
      checked: "checked",
      name: "name",
      value: "value"
    },
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function SComparisonTableChoiceItem_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2,
    changeDetection: 0
  });
};
SComparisonTableChoiceItem = __decorate([ProxyCmp({
  inputs: ['checked', 'name', 'value'],
  methods: ['setFocus', 'setBlur']
})], SComparisonTableChoiceItem);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SComparisonTableChoiceItem, [{
    type: Component,
    args: [{
      selector: 's-comparison-table-choice-item',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['checked', 'name', 'value']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let SComparisonTableLabelItem = class SComparisonTableLabelItem {
  z;
  el;
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  /** @nocollapse */
  static ɵfac = function SComparisonTableLabelItem_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || SComparisonTableLabelItem)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
  };
  /** @nocollapse */
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: SComparisonTableLabelItem,
    selectors: [["s-comparison-table-label-item"]],
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function SComparisonTableLabelItem_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2,
    changeDetection: 0
  });
};
SComparisonTableLabelItem = __decorate([ProxyCmp({})], SComparisonTableLabelItem);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SComparisonTableLabelItem, [{
    type: Component,
    args: [{
      selector: 's-comparison-table-label-item',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: []
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let SDialog = class SDialog {
  z;
  el;
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['sOpenDialog', 'sCloseDialog']);
  }
  /** @nocollapse */
  static ɵfac = function SDialog_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || SDialog)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
  };
  /** @nocollapse */
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: SDialog,
    selectors: [["s-dialog"]],
    inputs: {
      closeButton: "closeButton",
      dismissible: "dismissible",
      form: "form",
      headline: "headline",
      persistent: "persistent",
      show: "show"
    },
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function SDialog_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2,
    changeDetection: 0
  });
};
SDialog = __decorate([ProxyCmp({
  inputs: ['closeButton', 'dismissible', 'form', 'headline', 'persistent', 'show'],
  methods: ['open', 'close']
})], SDialog);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SDialog, [{
    type: Component,
    args: [{
      selector: 's-dialog',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['closeButton', 'dismissible', 'form', 'headline', 'persistent', 'show']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let SDivider = class SDivider {
  z;
  el;
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  /** @nocollapse */
  static ɵfac = function SDivider_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || SDivider)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
  };
  /** @nocollapse */
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: SDivider,
    selectors: [["s-divider"]],
    inputs: {
      alignX: "alignX",
      color: "color"
    },
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function SDivider_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2,
    changeDetection: 0
  });
};
SDivider = __decorate([ProxyCmp({
  inputs: ['alignX', 'color']
})], SDivider);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SDivider, [{
    type: Component,
    args: [{
      selector: 's-divider',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['alignX', 'color']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let SExpansionPanel = class SExpansionPanel {
  z;
  el;
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['sOpenExpansionPanel', 'sCloseExpansionPanel']);
  }
  /** @nocollapse */
  static ɵfac = function SExpansionPanel_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || SExpansionPanel)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
  };
  /** @nocollapse */
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: SExpansionPanel,
    selectors: [["s-expansion-panel"]],
    inputs: {
      disabled: "disabled",
      divider: "divider",
      headlineTag: "headlineTag",
      open: "open",
      readonly: "readonly"
    },
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function SExpansionPanel_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2,
    changeDetection: 0
  });
};
SExpansionPanel = __decorate([ProxyCmp({
  inputs: ['disabled', 'divider', 'headlineTag', 'open', 'readonly']
})], SExpansionPanel);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SExpansionPanel, [{
    type: Component,
    args: [{
      selector: 's-expansion-panel',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['disabled', 'divider', 'headlineTag', 'open', 'readonly']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let SExpansionPanels = class SExpansionPanels {
  z;
  el;
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  /** @nocollapse */
  static ɵfac = function SExpansionPanels_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || SExpansionPanels)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
  };
  /** @nocollapse */
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: SExpansionPanels,
    selectors: [["s-expansion-panels"]],
    inputs: {
      density: "density",
      multiple: "multiple"
    },
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function SExpansionPanels_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2,
    changeDetection: 0
  });
};
SExpansionPanels = __decorate([ProxyCmp({
  inputs: ['density', 'multiple']
})], SExpansionPanels);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SExpansionPanels, [{
    type: Component,
    args: [{
      selector: 's-expansion-panels',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['density', 'multiple']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let SIcon = class SIcon {
  z;
  el;
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['sClick']);
  }
  /** @nocollapse */
  static ɵfac = function SIcon_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || SIcon)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
  };
  /** @nocollapse */
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: SIcon,
    selectors: [["s-icon"]],
    inputs: {
      label: "label",
      name: "name",
      role: "role",
      size: "size",
      variant: "variant"
    },
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function SIcon_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2,
    changeDetection: 0
  });
};
SIcon = __decorate([ProxyCmp({
  inputs: ['label', 'name', 'role', 'size', 'variant']
})], SIcon);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SIcon, [{
    type: Component,
    args: [{
      selector: 's-icon',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['label', 'name', 'role', 'size', 'variant']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let SIconButton = class SIconButton {
  z;
  el;
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['sClick', 'sBlur', 'sFocus']);
  }
  /** @nocollapse */
  static ɵfac = function SIconButton_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || SIconButton)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
  };
  /** @nocollapse */
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: SIconButton,
    selectors: [["s-icon-button"]],
    inputs: {
      color: "color",
      density: "density",
      disabled: "disabled",
      download: "download",
      external: "external",
      icon: "icon",
      iconVariant: "iconVariant",
      label: "label",
      sTabindex: "sTabindex",
      shape: "shape",
      size: "size",
      type: "type",
      url: "url",
      value: "value",
      variant: "variant"
    },
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function SIconButton_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2,
    changeDetection: 0
  });
};
SIconButton = __decorate([ProxyCmp({
  inputs: ['color', 'density', 'disabled', 'download', 'external', 'icon', 'iconVariant', 'label', 'sTabindex', 'shape', 'size', 'type', 'url', 'value', 'variant'],
  methods: ['setFocus', 'setBlur', 'setClick']
})], SIconButton);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SIconButton, [{
    type: Component,
    args: [{
      selector: 's-icon-button',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'density', 'disabled', 'download', 'external', 'icon', 'iconVariant', 'label', 'sTabindex', 'shape', 'size', 'type', 'url', 'value', 'variant']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let SList = class SList {
  z;
  el;
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  /** @nocollapse */
  static ɵfac = function SList_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || SList)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
  };
  /** @nocollapse */
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: SList,
    selectors: [["s-list"]],
    inputs: {
      density: "density",
      divider: "divider"
    },
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function SList_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2,
    changeDetection: 0
  });
};
SList = __decorate([ProxyCmp({
  inputs: ['density', 'divider']
})], SList);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SList, [{
    type: Component,
    args: [{
      selector: 's-list',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['density', 'divider']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let SListItem = class SListItem {
  z;
  el;
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['sClick', 'sBlur', 'sFocus']);
  }
  /** @nocollapse */
  static ɵfac = function SListItem_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || SListItem)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
  };
  /** @nocollapse */
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: SListItem,
    selectors: [["s-list-item"]],
    inputs: {
      accessibleRole: "accessibleRole",
      activated: "activated",
      alignY: "alignY",
      clickable: "clickable",
      density: "density",
      disabled: "disabled",
      focusable: "focusable",
      leadingText: "leadingText",
      selected: "selected",
      supportingText: "supportingText",
      url: "url"
    },
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function SListItem_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2,
    changeDetection: 0
  });
};
SListItem = __decorate([ProxyCmp({
  inputs: ['accessibleRole', 'activated', 'alignY', 'clickable', 'density', 'disabled', 'focusable', 'leadingText', 'selected', 'supportingText', 'url'],
  methods: ['setFocus', 'setBlur', 'setClick']
})], SListItem);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SListItem, [{
    type: Component,
    args: [{
      selector: 's-list-item',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['accessibleRole', 'activated', 'alignY', 'clickable', 'density', 'disabled', 'focusable', 'leadingText', 'selected', 'supportingText', 'url']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let SMenu = class SMenu {
  z;
  el;
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['sShow', 'sHide', 'sSelected', 'sClick', 'sBlur', 'sFocus']);
  }
  /** @nocollapse */
  static ɵfac = function SMenu_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || SMenu)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
  };
  /** @nocollapse */
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: SMenu,
    selectors: [["s-menu"]],
    inputs: {
      activatorFullWidth: "activatorFullWidth",
      elevation: "elevation",
      fullWidth: "fullWidth",
      noActivatorClicks: "noActivatorClicks",
      placement: "placement",
      show: "show",
      stayOpenOnSelect: "stayOpenOnSelect"
    },
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function SMenu_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2,
    changeDetection: 0
  });
};
SMenu = __decorate([ProxyCmp({
  inputs: ['activatorFullWidth', 'elevation', 'fullWidth', 'noActivatorClicks', 'placement', 'show', 'stayOpenOnSelect'],
  methods: ['resyncItems']
})], SMenu);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SMenu, [{
    type: Component,
    args: [{
      selector: 's-menu',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['activatorFullWidth', 'elevation', 'fullWidth', 'noActivatorClicks', 'placement', 'show', 'stayOpenOnSelect']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let SMenuItem = class SMenuItem {
  z;
  el;
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['sClick']);
  }
  /** @nocollapse */
  static ɵfac = function SMenuItem_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || SMenuItem)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
  };
  /** @nocollapse */
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: SMenuItem,
    selectors: [["s-menu-item"]],
    inputs: {
      activated: "activated",
      choiceType: "choiceType",
      density: "density",
      disabled: "disabled",
      displayValue: "displayValue",
      label: "label",
      readonly: "readonly",
      selected: "selected",
      url: "url",
      value: "value"
    },
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function SMenuItem_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2,
    changeDetection: 0
  });
};
SMenuItem = __decorate([ProxyCmp({
  inputs: ['activated', 'choiceType', 'density', 'disabled', 'displayValue', 'label', 'readonly', 'selected', 'url', 'value'],
  methods: ['setFocus', 'setBlur', 'setClick']
})], SMenuItem);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SMenuItem, [{
    type: Component,
    args: [{
      selector: 's-menu-item',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['activated', 'choiceType', 'density', 'disabled', 'displayValue', 'label', 'readonly', 'selected', 'url', 'value']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let SMobileStepper = class SMobileStepper {
  z;
  el;
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  /** @nocollapse */
  static ɵfac = function SMobileStepper_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || SMobileStepper)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
  };
  /** @nocollapse */
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: SMobileStepper,
    selectors: [["s-mobile-stepper"]],
    inputs: {
      activeStep: "activeStep",
      maxSteps: "maxSteps",
      variant: "variant"
    },
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function SMobileStepper_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2,
    changeDetection: 0
  });
};
SMobileStepper = __decorate([ProxyCmp({
  inputs: ['activeStep', 'maxSteps', 'variant']
})], SMobileStepper);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SMobileStepper, [{
    type: Component,
    args: [{
      selector: 's-mobile-stepper',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['activeStep', 'maxSteps', 'variant']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let SProgressCircular = class SProgressCircular {
  z;
  el;
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  /** @nocollapse */
  static ɵfac = function SProgressCircular_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || SProgressCircular)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
  };
  /** @nocollapse */
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: SProgressCircular,
    selectors: [["s-progress-circular"]],
    inputs: {
      color: "color",
      indeterminate: "indeterminate",
      size: "size",
      value: "value"
    },
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function SProgressCircular_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2,
    changeDetection: 0
  });
};
SProgressCircular = __decorate([ProxyCmp({
  inputs: ['color', 'indeterminate', 'size', 'value']
})], SProgressCircular);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SProgressCircular, [{
    type: Component,
    args: [{
      selector: 's-progress-circular',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'indeterminate', 'size', 'value']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let SProgressLinear = class SProgressLinear {
  z;
  el;
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  /** @nocollapse */
  static ɵfac = function SProgressLinear_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || SProgressLinear)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
  };
  /** @nocollapse */
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: SProgressLinear,
    selectors: [["s-progress-linear"]],
    inputs: {
      color: "color",
      indeterminate: "indeterminate",
      max: "max",
      showProgressIndicator: "showProgressIndicator",
      value: "value"
    },
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function SProgressLinear_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2,
    changeDetection: 0
  });
};
SProgressLinear = __decorate([ProxyCmp({
  inputs: ['color', 'indeterminate', 'max', 'showProgressIndicator', 'value']
})], SProgressLinear);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SProgressLinear, [{
    type: Component,
    args: [{
      selector: 's-progress-linear',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['color', 'indeterminate', 'max', 'showProgressIndicator', 'value']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let SRadio = class SRadio {
  z;
  el;
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['sChange', 'sRadioChange', 'sBlur', 'sFocus']);
  }
  /** @nocollapse */
  static ɵfac = function SRadio_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || SRadio)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
  };
  /** @nocollapse */
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: SRadio,
    selectors: [["s-radio"]],
    inputs: {
      checked: "checked",
      disabled: "disabled",
      name: "name",
      readonly: "readonly",
      required: "required",
      severity: "severity",
      value: "value"
    },
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function SRadio_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2,
    changeDetection: 0
  });
};
SRadio = __decorate([ProxyCmp({
  inputs: ['checked', 'disabled', 'name', 'readonly', 'required', 'severity', 'value'],
  methods: ['setFocus', 'setBlur']
})], SRadio);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SRadio, [{
    type: Component,
    args: [{
      selector: 's-radio',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['checked', 'disabled', 'name', 'readonly', 'required', 'severity', 'value']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let SSegmentedButtons = class SSegmentedButtons {
  z;
  el;
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['sChangeSegmentedButtons']);
  }
  /** @nocollapse */
  static ɵfac = function SSegmentedButtons_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || SSegmentedButtons)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
  };
  /** @nocollapse */
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: SSegmentedButtons,
    selectors: [["s-segmented-buttons"]],
    inputs: {
      disabled: "disabled",
      fullWidth: "fullWidth",
      scrollButtons: "scrollButtons",
      value: "value"
    },
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function SSegmentedButtons_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2,
    changeDetection: 0
  });
};
SSegmentedButtons = __decorate([ProxyCmp({
  inputs: ['disabled', 'fullWidth', 'scrollButtons', 'value']
})], SSegmentedButtons);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SSegmentedButtons, [{
    type: Component,
    args: [{
      selector: 's-segmented-buttons',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['disabled', 'fullWidth', 'scrollButtons', 'value']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let SSelect = class SSelect {
  z;
  el;
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['sChange', 'sBlur', 'sFocus', 'sShow', 'sHide']);
  }
  /** @nocollapse */
  static ɵfac = function SSelect_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || SSelect)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
  };
  /** @nocollapse */
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: SSelect,
    selectors: [["s-select"]],
    inputs: {
      density: "density",
      disabled: "disabled",
      filled: "filled",
      multiple: "multiple",
      placeholder: "placeholder",
      readonly: "readonly",
      required: "required",
      severity: "severity",
      show: "show",
      value: "value"
    },
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function SSelect_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2,
    changeDetection: 0
  });
};
SSelect = __decorate([ProxyCmp({
  inputs: ['density', 'disabled', 'filled', 'multiple', 'placeholder', 'readonly', 'required', 'severity', 'show', 'value'],
  methods: ['setFocus', 'setBlur']
})], SSelect);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SSelect, [{
    type: Component,
    args: [{
      selector: 's-select',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['density', 'disabled', 'filled', 'multiple', 'placeholder', 'readonly', 'required', 'severity', 'show', 'value']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let SSideSheet = class SSideSheet {
  z;
  el;
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['sOpenSideSheet', 'sCloseSideSheet']);
  }
  /** @nocollapse */
  static ɵfac = function SSideSheet_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || SSideSheet)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
  };
  /** @nocollapse */
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: SSideSheet,
    selectors: [["s-side-sheet"]],
    inputs: {
      backdrop: "backdrop",
      closeButton: "closeButton",
      dismissible: "dismissible",
      form: "form",
      headline: "headline",
      location: "location",
      persistent: "persistent",
      shape: "shape",
      show: "show"
    },
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function SSideSheet_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2,
    changeDetection: 0
  });
};
SSideSheet = __decorate([ProxyCmp({
  inputs: ['backdrop', 'closeButton', 'dismissible', 'form', 'headline', 'location', 'persistent', 'shape', 'show'],
  methods: ['open', 'close']
})], SSideSheet);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SSideSheet, [{
    type: Component,
    args: [{
      selector: 's-side-sheet',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['backdrop', 'closeButton', 'dismissible', 'form', 'headline', 'location', 'persistent', 'shape', 'show']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let SSlideGroup = class SSlideGroup {
  z;
  el;
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  /** @nocollapse */
  static ɵfac = function SSlideGroup_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || SSlideGroup)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
  };
  /** @nocollapse */
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: SSlideGroup,
    selectors: [["s-slide-group"]],
    inputs: {
      autoScrollButtons: "autoScrollButtons",
      gap: "gap",
      justifyActiveItem: "justifyActiveItem",
      scrollButtons: "scrollButtons",
      scrollButtonsSize: "scrollButtonsSize",
      showDelimiters: "showDelimiters",
      step: "step",
      visibleItems: "visibleItems"
    },
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function SSlideGroup_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2,
    changeDetection: 0
  });
};
SSlideGroup = __decorate([ProxyCmp({
  inputs: ['autoScrollButtons', 'gap', 'justifyActiveItem', 'scrollButtons', 'scrollButtonsSize', 'showDelimiters', 'step', 'visibleItems']
})], SSlideGroup);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SSlideGroup, [{
    type: Component,
    args: [{
      selector: 's-slide-group',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['autoScrollButtons', 'gap', 'justifyActiveItem', 'scrollButtons', 'scrollButtonsSize', 'showDelimiters', 'step', 'visibleItems']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let SSlider = class SSlider {
  z;
  el;
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['sChange']);
  }
  /** @nocollapse */
  static ɵfac = function SSlider_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || SSlider)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
  };
  /** @nocollapse */
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: SSlider,
    selectors: [["s-slider"]],
    inputs: {
      alignY: "alignY",
      ariaDisabled: "ariaDisabled",
      disabled: "disabled",
      labelFormatter: "labelFormatter",
      max: "max",
      min: "min",
      name: "name",
      negative: "negative",
      readonly: "readonly",
      sTabIndex: "sTabIndex",
      showLabels: "showLabels",
      showMarks: "showMarks",
      showStopLabels: "showStopLabels",
      showSupportingTexts: "showSupportingTexts",
      showTooltip: "showTooltip",
      snapOffset: "snapOffset",
      step: "step",
      stops: "stops",
      supportingTextFormatter: "supportingTextFormatter",
      swappable: "swappable",
      tooltipFormatter: "tooltipFormatter",
      value: "value"
    },
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function SSlider_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2,
    changeDetection: 0
  });
};
SSlider = __decorate([ProxyCmp({
  inputs: ['alignY', 'ariaDisabled', 'disabled', 'labelFormatter', 'max', 'min', 'name', 'negative', 'readonly', 'sTabIndex', 'showLabels', 'showMarks', 'showStopLabels', 'showSupportingTexts', 'showTooltip', 'snapOffset', 'step', 'stops', 'supportingTextFormatter', 'swappable', 'tooltipFormatter', 'value'],
  methods: ['focusInner']
})], SSlider);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SSlider, [{
    type: Component,
    args: [{
      selector: 's-slider',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['alignY', 'ariaDisabled', 'disabled', 'labelFormatter', 'max', 'min', 'name', 'negative', 'readonly', 'sTabIndex', 'showLabels', 'showMarks', 'showStopLabels', 'showSupportingTexts', 'showTooltip', 'snapOffset', 'step', 'stops', 'supportingTextFormatter', 'swappable', 'tooltipFormatter', 'value']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let SStep = class SStep {
  z;
  el;
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['sClick', 'sBlur', 'sFocus']);
  }
  /** @nocollapse */
  static ɵfac = function SStep_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || SStep)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
  };
  /** @nocollapse */
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: SStep,
    selectors: [["s-step"]],
    inputs: {
      clickable: "clickable",
      leadingText: "leadingText",
      severity: "severity",
      stacked: "stacked",
      supportingText: "supportingText",
      variant: "variant"
    },
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function SStep_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2,
    changeDetection: 0
  });
};
SStep = __decorate([ProxyCmp({
  inputs: ['clickable', 'leadingText', 'severity', 'stacked', 'supportingText', 'variant']
})], SStep);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SStep, [{
    type: Component,
    args: [{
      selector: 's-step',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['clickable', 'leadingText', 'severity', 'stacked', 'supportingText', 'variant']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let SStepper = class SStepper {
  z;
  el;
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
  }
  /** @nocollapse */
  static ɵfac = function SStepper_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || SStepper)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
  };
  /** @nocollapse */
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: SStepper,
    selectors: [["s-stepper"]],
    inputs: {
      stacked: "stacked"
    },
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function SStepper_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2,
    changeDetection: 0
  });
};
SStepper = __decorate([ProxyCmp({
  inputs: ['stacked']
})], SStepper);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SStepper, [{
    type: Component,
    args: [{
      selector: 's-stepper',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['stacked']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let SSwitch = class SSwitch {
  z;
  el;
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['sChange', 'sBlur', 'sFocus']);
  }
  /** @nocollapse */
  static ɵfac = function SSwitch_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || SSwitch)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
  };
  /** @nocollapse */
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: SSwitch,
    selectors: [["s-switch"]],
    inputs: {
      checked: "checked",
      disabled: "disabled",
      icon: "icon",
      label: "label",
      labelledby: "labelledby",
      name: "name",
      readonly: "readonly",
      required: "required",
      severity: "severity",
      value: "value"
    },
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function SSwitch_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2,
    changeDetection: 0
  });
};
SSwitch = __decorate([ProxyCmp({
  inputs: ['checked', 'disabled', 'icon', 'label', 'labelledby', 'name', 'readonly', 'required', 'severity', 'value']
})], SSwitch);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SSwitch, [{
    type: Component,
    args: [{
      selector: 's-switch',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['checked', 'disabled', 'icon', 'label', 'labelledby', 'name', 'readonly', 'required', 'severity', 'value']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let STabItem = class STabItem {
  z;
  el;
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['sActivateTabItem']);
  }
  /** @nocollapse */
  static ɵfac = function STabItem_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || STabItem)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
  };
  /** @nocollapse */
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: STabItem,
    selectors: [["s-tab-item"]],
    inputs: {
      active: "active",
      ariaControls: "ariaControls",
      disabled: "disabled",
      stacked: "stacked",
      url: "url",
      value: "value"
    },
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function STabItem_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2,
    changeDetection: 0
  });
};
STabItem = __decorate([ProxyCmp({
  inputs: ['active', 'ariaControls', 'disabled', 'stacked', 'url', 'value'],
  methods: ['activate']
})], STabItem);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(STabItem, [{
    type: Component,
    args: [{
      selector: 's-tab-item',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['active', 'ariaControls', 'disabled', 'stacked', 'url', 'value']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let STabs = class STabs {
  z;
  el;
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['sChangeTabs']);
  }
  /** @nocollapse */
  static ɵfac = function STabs_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || STabs)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
  };
  /** @nocollapse */
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: STabs,
    selectors: [["s-tabs"]],
    inputs: {
      autoActivate: "autoActivate",
      centered: "centered",
      direction: "direction",
      fullWidth: "fullWidth",
      label: "label",
      scrollButtons: "scrollButtons",
      value: "value"
    },
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function STabs_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2,
    changeDetection: 0
  });
};
STabs = __decorate([ProxyCmp({
  inputs: ['autoActivate', 'centered', 'direction', 'fullWidth', 'label', 'scrollButtons', 'value']
})], STabs);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(STabs, [{
    type: Component,
    args: [{
      selector: 's-tabs',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['autoActivate', 'centered', 'direction', 'fullWidth', 'label', 'scrollButtons', 'value']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let STextField = class STextField {
  z;
  el;
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['sChange', 'sBlur', 'sFocus', 'sInput', 'sAutoFilled']);
  }
  /** @nocollapse */
  static ɵfac = function STextField_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || STextField)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
  };
  /** @nocollapse */
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: STextField,
    selectors: [["s-text-field"]],
    inputs: {
      ariaLabel: "ariaLabel",
      autocomplete: "autocomplete",
      autofocus: "autofocus",
      clearable: "clearable",
      density: "density",
      disabled: "disabled",
      filled: "filled",
      fullWidth: "fullWidth",
      inputMode: "inputMode",
      inputType: "inputType",
      max: "max",
      maxlength: "maxlength",
      min: "min",
      minlength: "minlength",
      name: "name",
      pattern: "pattern",
      placeholder: "placeholder",
      readonly: "readonly",
      required: "required",
      severity: "severity",
      showCharacterCount: "showCharacterCount",
      spellcheck: "spellcheck",
      step: "step",
      value: "value"
    },
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function STextField_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2,
    changeDetection: 0
  });
};
STextField = __decorate([ProxyCmp({
  inputs: ['ariaLabel', 'autocomplete', 'autofocus', 'clearable', 'density', 'disabled', 'filled', 'fullWidth', 'inputMode', 'inputType', 'max', 'maxlength', 'min', 'minlength', 'name', 'pattern', 'placeholder', 'readonly', 'required', 'severity', 'showCharacterCount', 'spellcheck', 'step', 'value'],
  methods: ['setFocus', 'setBlur', 'showPicker']
})], STextField);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(STextField, [{
    type: Component,
    args: [{
      selector: 's-text-field',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['ariaLabel', 'autocomplete', 'autofocus', 'clearable', 'density', 'disabled', 'filled', 'fullWidth', 'inputMode', 'inputType', 'max', 'maxlength', 'min', 'minlength', 'name', 'pattern', 'placeholder', 'readonly', 'required', 'severity', 'showCharacterCount', 'spellcheck', 'step', 'value']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let STextarea = class STextarea {
  z;
  el;
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['sChange', 'sBlur', 'sFocus', 'sInput', 'sBeforeClear']);
  }
  /** @nocollapse */
  static ɵfac = function STextarea_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || STextarea)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
  };
  /** @nocollapse */
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: STextarea,
    selectors: [["s-textarea"]],
    inputs: {
      autofocus: "autofocus",
      autogrow: "autogrow",
      clearable: "clearable",
      density: "density",
      disabled: "disabled",
      filled: "filled",
      fullWidth: "fullWidth",
      label: "label",
      maxRows: "maxRows",
      maxlength: "maxlength",
      minlength: "minlength",
      name: "name",
      placeholder: "placeholder",
      readonly: "readonly",
      required: "required",
      resize: "resize",
      rows: "rows",
      severity: "severity",
      showCharacterCount: "showCharacterCount",
      spellcheck: "spellcheck",
      value: "value"
    },
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function STextarea_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2,
    changeDetection: 0
  });
};
STextarea = __decorate([ProxyCmp({
  inputs: ['autofocus', 'autogrow', 'clearable', 'density', 'disabled', 'filled', 'fullWidth', 'label', 'maxRows', 'maxlength', 'minlength', 'name', 'placeholder', 'readonly', 'required', 'resize', 'rows', 'severity', 'showCharacterCount', 'spellcheck', 'value'],
  methods: ['setFocus', 'setBlur']
})], STextarea);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(STextarea, [{
    type: Component,
    args: [{
      selector: 's-textarea',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['autofocus', 'autogrow', 'clearable', 'density', 'disabled', 'filled', 'fullWidth', 'label', 'maxRows', 'maxlength', 'minlength', 'name', 'placeholder', 'readonly', 'required', 'resize', 'rows', 'severity', 'showCharacterCount', 'spellcheck', 'value']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
let STooltip = class STooltip {
  z;
  el;
  constructor(c, r, z) {
    this.z = z;
    c.detach();
    this.el = r.nativeElement;
    proxyOutputs(this, this.el, ['sShow', 'sHide']);
  }
  /** @nocollapse */
  static ɵfac = function STooltip_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || STooltip)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef), i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.NgZone));
  };
  /** @nocollapse */
  static ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
    type: STooltip,
    selectors: [["s-tooltip"]],
    inputs: {
      arrow: "arrow",
      color: "color",
      content: "content",
      open: "open",
      openOn: "openOn",
      placement: "placement"
    },
    ngContentSelectors: _c0,
    decls: 1,
    vars: 0,
    template: function STooltip_Template(rf, ctx) {
      if (rf & 1) {
        i0.ɵɵprojectionDef();
        i0.ɵɵprojection(0);
      }
    },
    encapsulation: 2,
    changeDetection: 0
  });
};
STooltip = __decorate([ProxyCmp({
  inputs: ['arrow', 'color', 'content', 'open', 'openOn', 'placement']
})], STooltip);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(STooltip, [{
    type: Component,
    args: [{
      selector: 's-tooltip',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: '<ng-content></ng-content>',
      // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
      inputs: ['arrow', 'color', 'content', 'open', 'openOn', 'placement']
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }, {
    type: i0.ElementRef
  }, {
    type: i0.NgZone
  }], null);
})();
const DIRECTIVES = [SBackdrop, SBadge, SBanner, SBottomSheet, SBreadcrumb, SButton, SCard, SCheckbox, SChip, SChoiceGroup, SChoiceList, SChoiceListItem, SComparisonTable, SComparisonTableChoiceItem, SComparisonTableLabelItem, SDialog, SDivider, SExpansionPanel, SExpansionPanels, SIcon, SIconButton, SList, SListItem, SMenu, SMenuItem, SMobileStepper, SProgressCircular, SProgressLinear, SRadio, SSegmentedButtons, SSelect, SSideSheet, SSlideGroup, SSlider, SStep, SStepper, SSwitch, STabItem, STabs, STextField, STextarea, STooltip];
const DECLARATIONS = [
// generated proxies
...DIRECTIVES,
// ngModel accessors
BooleanValueAccessor, NumericValueAccessor, TextValueAccessor];
class ShieldModule {
  /** @nocollapse */static ɵfac = function ShieldModule_Factory(__ngFactoryType__) {
    return new (__ngFactoryType__ || ShieldModule)();
  };
  /** @nocollapse */
  static ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
    type: ShieldModule
  });
  /** @nocollapse */
  static ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
    imports: [CommonModule]
  });
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ShieldModule, [{
    type: NgModule,
    args: [{
      declarations: DECLARATIONS,
      exports: DECLARATIONS,
      imports: [CommonModule]
    }]
  }], null, null);
})();

// DIRECTIVES

/**
 * Generated bundle index. Do not edit.
 */

export { BooleanValueAccessor, NumericValueAccessor, SBackdrop, SBadge, SBanner, SBottomSheet, SBreadcrumb, SButton, SCard, SCheckbox, SChip, SChoiceGroup, SChoiceList, SChoiceListItem, SComparisonTable, SComparisonTableChoiceItem, SComparisonTableLabelItem, SDialog, SDivider, SExpansionPanel, SExpansionPanels, SIcon, SIconButton, SList, SListItem, SMenu, SMenuItem, SMobileStepper, SProgressCircular, SProgressLinear, SRadio, SSegmentedButtons, SSelect, SSideSheet, SSlideGroup, SSlider, SStep, SStepper, SSwitch, STabItem, STabs, STextField, STextarea, STooltip, ShieldModule, TextValueAccessor };
