<s-dialog class="cookie-dialog" close-button="true" dismissible="false" show="true">
  <div slot="prepend">
    <s-button
            class="back-button"
      *ngIf="showCookieDetails"
      color="primary"
      size="xs"
      variant="text"
      (click)="toggleCookieDetails()"
    >
      <s-icon name="chevron-left" size="md"></s-icon>
      <span>zurück</span>
    </s-button>
    <h6 *ngIf="!showCookieDetails">Privatsphäre-Einstellungen</h6>
  </div>
  <div *ngIf="!showCookieDetails">
    <p class="s:pb-6">
      Wir verwenden Cookies und ähnliche Technologien, um die technische
      Funktionsfähigkeit und Sicherheit dieser Website zu gewährleisten, sowie
      auf statistischer Basis Reichweitenmessungen durchzuführen (erforderliche
      Einstellungen). Erforderliche Einstellungen können nicht abgewählt werden.
      Für weitergehende Analysen des Nutzungsverhaltens, der Anzeige
      interessenbasierter Werbung auch außerhalb unserer Website und um Ihnen
      den bestmöglichen Service zu bieten, setzen wir mit Ihrem Einverständnis
      verschiedene Cookies oder ähnliche Technologien ein, auch von
      Drittanbietern (optionale Einstellungen).
    </p>
    <p class="s:pb-6">
      <b
        >Die optionalen Einstellungen werden nur verwendet, sofern Sie – durch
        Klick auf Zustimmen – Ihre Erlaubnis hierzu erteilen.</b
      >
    </p>
    <p class="s:pb-6">
      Weitere Informationen finden Sie in unserer
      <a
        #dataPrivacyLink
        class="s:text-underline s:text"
        target="_blank"
        href="/datenschutz"
        >Datenschutzerklärung</a
      >
    </p>
    <p>
      Ihre getroffenen Einstellungen können Sie jederzeit unter dem Punkt
      <b>Privatsphäre-Einstellungen</b> am Ende jeder Seite anpassen.
    </p>
  </div>
  <div *ngIf="showCookieDetails">
    <p class="s:pb-6">
      Erforderliche Einstellungen erlauben es uns, die technische
      Funktionsfähigkeit unserer Website sicherzustellen und die Sicherheit der
      Website zu gewährleisten.<br /><br />
      Eine vollständige Zuordnung einzelner Verarbeitung finden Sie in unserer ›
      <a class="s:text-underline s:text" target="_blank" href="/datenschutz"
        >Datenschutzerklärung</a
      ><br /><br />
      Rechtsgrundlage für die jeweilige Verarbeitung stellt Art. 6 Abs. 1 lit.f)
      DSGVO sowie § 25 Abs.2 Nr.2 TTDSG dar.
    </p>
    <div class="details">
      <s-expansion-panels>
        <s-expansion-panel
          (sOpenExpansionPanel1)="toggleDetailBlock1(true)"
          (sCloseExpansionPanel1)="toggleDetailBlock1(false)"
        >
          <span class="s:text-h6" slot="headline"
            ><p>{{ detailBlock1Title }}</p></span
          >
          <div slot="content">
            <div class="row">
              <span><b>Anwendungsbereich</b></span>
              <span><b>Speichertechnologien</b></span>
            </div>
            <div class="row">
              <span>Speicherung Ihrer Angaben während einer Session</span>
              <span>Cookies,<br />Sessionstorage,<br />Localstorage</span>
            </div>
            <s-divider></s-divider>
            <div class="row">
              <span
                >Sichere Datenvorbefüllung und Speicherung der
                Nutzerinformationen</span
              >
              <span>Cookies,<br />Sessionstorage,<br />Localstorage</span>
            </div>
            <s-divider></s-divider>
            <div class="row">
              <span>Fehlererkennung zur Optimierung unserer Website</span>
              <span>Cookies,<br />Sessionstorage,<br />Localstorage</span>
            </div>
            <s-divider></s-divider>
            <div class="row">
              <span
                >Statistische Analysen zur Optimierung der
                Website-Funktionalität</span
              >
              <span>Cookies</span>
            </div>
          </div>
        </s-expansion-panel>
      </s-expansion-panels>
      <p class="s:pb-6">
        Optionale Einstellungen gewähren uns Informationen über Ihre konkreten
        Besuche auf unserer Website und den Inhalten, die Sie sich angeschaut
        haben. Sie helfen uns, für Sie relevante Inhalte, Angebote und
        Werbeanzeigen zu identifizieren, auf Ihre Interessen abzustimmen und
        Ihnen diese anzuzeigen. Außerdem übermitteln sie uns statistische
        Erkenntnisse darüber, wie unsere Website genutzt wird und ermöglichen es
        uns, den Erfolg unserer Kampagnen messen zu können.<br /><br />
        Die optionalen Einstellungen werden nur verwendet, sofern Sie – durch
        Klick auf Zustimmen – Ihr Einverständnis hierzu erteilen.
      </p>
      <div class="details">
        <s-expansion-panels>
          <s-expansion-panel
            (sOpenExpansionPanel2)="toggleDetailBlock1(true)"
            (sCloseExpansionPanel2)="toggleDetailBlock1(false)"
          >
            <span class="s:text-h6" slot="headline"
              ><p>{{ detailBlock2Title }}</p></span
            >
            <div slot="content">
              <div class="row">
                <span><b>Bezeichnung</b></span>
                <span><b>Speichertechnologie (Laufzeit in Tagen)</b></span>
              </div>
              <div class="row">
                <span>(HUK) ng_id </span>
                <span>Cookie (Session)</span>
              </div>
              <s-divider></s-divider>
              <div class="row">
                <span>(Datadog) _dd_s</span>
                <span>Cookie (Session)</span>
              </div>
              <s-divider></s-divider>
              <div class="row">
                <span>(Datadog) dd_site_test</span>
                <span>Cookie (Session)</span>
              </div>
              <s-divider></s-divider>
              <div class="row">
                <span>(Datadog) dd_cookie_test_</span>
                <span>Cookie (Session)</span>
              </div>
            </div>
          </s-expansion-panel>
        </s-expansion-panels>
      </div>
    </div>
  </div>
    <menu>
        <s-button class="s:mt-2 s:mb-2" full-width="true" (click)="closeCookieBanner(true)">Zustimmen
        </s-button>
        <s-button class="s:mt-2 s:mb-2" full-width="true" type="submit" variant="outlined" (click)="closeCookieBanner(false)">
            Mit erforderlichen Einstellungen fortfahren
        </s-button>
        <s-button *ngIf="!showCookieDetails" class="s:mt-2 s:mb-2" full-width="true" type="submit" variant="outlined"
                  (click)="toggleCookieDetails()">
            Einstellungen anzeigen
        </s-button>
    </menu>
</s-dialog>
