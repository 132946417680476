import {
  ApplicationRef,
  ComponentRef,
  createComponent,
  DestroyRef,
  inject,
  Injectable,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { datadogRum } from "@datadog/browser-rum";
import { BehaviorSubject, fromEvent } from "rxjs";
import { CookieBannerComponent } from "../cookie-banner/cookie-banner.component";

@Injectable({
  providedIn: "root",
})
export class CookieBannerService {
  private cookieBannerRef: ComponentRef<CookieBannerComponent> | null = null;
  private hostElement: HTMLElement | null = null;
  private storage$ = fromEvent(window, "storage");
  private readonly isCookieBannerDisplayed = new BehaviorSubject<boolean>(
    false
  );
  private readonly appRef = inject(ApplicationRef);
  private readonly appRoot = document.getElementById("app-root");
  private readonly destroyRef = inject(DestroyRef);

  public constructor() {
    this.subscribeToDisplayState();
    this.subscribeToLocalStorageChanges();
  }

  public showCookieBanner() {
    this.isCookieBannerDisplayed.next(true);
  }

  public closeCookieBanner() {
    this.isCookieBannerDisplayed.next(false);
  }

  private createAndAttachCookieBanner() {
    if (!this.appRoot) {
      return;
    }

    if (!this.hostElement) {
      const cookieBannerContainer = document.createElement("div");
      cookieBannerContainer.id = "cookie-banner";
      this.hostElement = cookieBannerContainer;
    }

    this.appRoot.appendChild(this.hostElement);

    this.cookieBannerRef = createComponent(CookieBannerComponent, {
      hostElement: this.hostElement,
      environmentInjector: this.appRef.injector,
    });
    this.appRef.attachView(this.cookieBannerRef.hostView);
  }

  private subscribeToDisplayState() {
    this.isCookieBannerDisplayed
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((displayState) => {
        if (displayState) {
          this.createAndAttachCookieBanner();
        } else {
          this.cookieBannerRef?.destroy();
          this.cookieBannerRef = null;
        }
      });
  }

  private subscribeToLocalStorageChanges() {
    this.storage$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((event) => {
        const storageEvent = event as StorageEvent;
        if (storageEvent.key === "privacy_consent") {
          const consent = storageEvent.newValue === "true";
          if (consent) {
            datadogRum.setTrackingConsent("granted");
          } else {
            datadogRum.setTrackingConsent("not-granted");
          }
        }
      });
  }
}
